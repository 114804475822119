import useUser from "./useUser";
import { useEffect } from "react";
import { navigate } from "@reach/router";

export default function useIfLoggedIn() {
  const { token } = useUser();

  useEffect(() => {
    if (token) {
      navigate("/transcriber/cases?caseType=arbitration");
    }
  });
}
