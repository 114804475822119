import React, { useState, useRef, Fragment } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import CustomTable from "../../common/CustomTable/CustomTable";
import _ from "lodash";
import queryString from "query-string";
import { useEffect } from "react";
import TranscriberService from "../../../services/TranscriberService";
import { navigate } from "@reach/router";
import theme from "../../../assets/theme";
import usePrevious from "../../../hooks/usePrevious";
import { ActionButton } from "../Buttons";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";

const initFilters = [
  {
    label: "Meeting Audio Transcripts",
    key: "meetingAudioTranscriptsCount",
    value: 0,
  },
  {
    label: "Live",
    key: "liveTranscriptCount",
    value: 0,
  },
  {
    label: "Recording Transcripts",
    key: "recordingTranscriptsCount",
    value: 0,
  },
];

const filterByStatus = (selectedFilter) => {
  let key = "status";
  let value = "";
  switch (selectedFilter) {
    case "Meeting Audio Transcripts":
      value = "meetingAudioTranscriptsCount";
      break;
    case "Live":
      value = "live";
      break;
    case "Recording Transcripts":
      value = "recordingTranscripts";
      break;
    default:
      break;
  }
  return { key, value };
};

async function getCasesTranscribers(query = "", caseType) {
  try {
    const param = `?resolutionKind=${caseType}`;
    const response = await TranscriberService.getCasesTranscribers(query);
    const stats = await TranscriberService.caseStatsTranscribers(param);
    return { ...response, stats };
  } catch (error) {
    throw error;
  }
}

let previousCaseType;

const Index = ({ id, caseType = null, currentCaseType }) => {
  const [state, setState] = useState({});
  const placeholderText = "Search Cases";
  const MTRef = useRef();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(initFilters);
  const [selectedFilter, setSelectedFilter] = useState(filters[0].label);
  const filterPreviousValue = usePrevious(caseType);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();

  function setStats(stats) {
    const mapped = initFilters.map((filter) => {
      let returnData = {
        label: filter.label,
        value: filter.value,
      };
      if (stats[filter.key] >= 0) {
        returnData.value = stats[filter.key];
      }
      return returnData;
    });
    setFilters(mapped);
  }

  useEffect(() => {
    if (caseType && filterPreviousValue) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseType]);

  /**
   * @description submit
   */

  const submitLiveTranscription = async (meetingId) => {
    try {
      setLoader({ state: true, message: "Submit..." });
      const res = await TranscriberService.submitLiveTranscription(meetingId);
      if (res) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    }
  };

  const submitVideoRecTranscription = async (transcriptId) => {
    try {
      setLoader({ state: true, message: "Submit..." });
      const res = await TranscriberService.submitVideoRecTranscription(
        transcriptId,
      );
      if (res) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    }
  };

  const submitAudioRecTranscription = async (meetingAudioId) => {
    try {
      setLoader({ state: true, message: "Submit..." });
      const res = await TranscriberService.submitAudioRecTranscription(
        meetingAudioId,
      );
      if (res) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
    }
  };

  const columns = [
    {
      field: "id",
      title: "Case ID",
      // sorting: true,
      tooltip: "Unique Identifier for the Case across JustAct platform",
      render: (rowData) => (
        <Bold style={{ paddingLeft: "22px" }}>{rowData.caseId}</Bold>
      ),
    },
    {
      field: "title",
      title: "Case Title",
      render: (rowData) => (
        <div style={{ cursor: "pointer" }}>{rowData.title}</div>
      ),
      sorting: false,
    },
    {
      field: "audioFileName",
      title: "Transcript",
      sorting: false,
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            selectedFilter === "Live"
              ? navigate(
                rowData?.audioFileName
                  ? `/transcriber/cases/${rowData.caseId}/livetranscription?caseType=${caseType}`
                  : `/transcriber/cases/${rowData.caseId}/cross-examination?caseType=${caseType}`,
                {
                  state: rowData,
                  transcriberAccess: rowData?.transcriberAccess,
                },
              )
              : selectedFilter === "Meeting Audio Transcripts"
                ? navigate(
                  `/transcriber/cases/${rowData.caseId}/transcription?caseType=${caseType}`,
                  {
                    state: rowData,
                    transcriberAccess: rowData?.transcriberAccess,
                  },
                )
                : navigate(
                  `/transcriber/cases/${rowData.caseId}/recording-transcription?caseType=${caseType}`,
                  {
                    state: rowData,
                    transcriberAccess: rowData?.transcriberAccess,
                  },
                )
          }
        >
          {rowData?.audioFileName
            ? rowData?.audioFileName
            : "Cross Examination"}
        </HyperLink>
      ),
    },
    {
      field: "",
      title: "Action",
      render: (rowData) =>
        rowData?.audioFileName && rowData?.transcriberAccess === "editor" ? (
          <ActionButton
            style={{ margin: 0, height: 30, width: 90, fontSize: 10 }}
            onClick={() =>
              selectedFilter === "Live"
                ? submitLiveTranscription(rowData?.meetingId)
                : selectedFilter === "Meeting Audio Transcripts"
                  ? submitAudioRecTranscription(rowData?.meetingAudioDetId)
                  : submitVideoRecTranscription(rowData?.transcriptId)
            }
          >
            Submit
          </ActionButton>
        ) : (
          <div style={{ marginLeft: "15px" }}>-</div>
        ),
      sorting: false,
      // hidden: selectedFilter === "Live" ? false : true,
    },
  ];

  const data = (query) =>
    new Promise((resolve, reject) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (previousCaseType !== currentCaseType) {
        params.page = 1;
        setPage(params.page);
      }
      previousCaseType = currentCaseType;
      if (query.search) {
        params.search = query.search;
      }
      if (caseType) {
        params.resolutionKind = caseType;
      }
      if (id) {
        params.caseManagerId = id;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      const { key, value } = filterByStatus(selectedFilter);
      if (key && value) {
        params[key] = value;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getCasesTranscribers(stringParams, caseType)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
            setStats(result.stats);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  return (
    <Fragment>
      <CustomTable
        hidePagination={state.lastPage === 1}
        pageSize={state?.data?.length ? state?.data?.length : 10}
        pluralTitle="Cases"
        singularTitle=""
        {...{
          columns,
          data,
          placeholderText,
          MTRef,
          filters,
          selectedFilter,
          setSelectedFilter,
          page,
          setPage,
        }}
        // hideFilterBar
        state={state}
      />
    </Fragment>
  );
};

export default Index;

const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
`;

const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
