import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import { CloudDownloadRounded, Keyboard } from "@material-ui/icons";

const Header = React.memo(
  ({
    showSettings,
    showExportOptions,
    setShowSettings,
    setShowExportOptions,
    settings,
    mediaControls,
    mediaUrl,
    exportOptions,
    shortCutKeys,
    showShortcuts,
    setShowShortCuts,
  }) => {
    async function downloadFromS3(s3Url) {
      try {
        // Fetch the file from the S3 URL
        const response = await fetch(s3Url, {
          headers: new Headers({
            Origin: window.location.origin,
          }),
          mode: "cors",
        });

        // const response = await fetch(
        //   `${REACT_APP_BASE_API_URL}/download?s3Url=${encodeURIComponent(
        //     s3Url
        //   )}`
        // );

        // Get the blob data from the fetched response
        const blob = await response.blob();

        // Create a temporary anchor element to trigger download
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        // Set the filename for the downloaded file
        const filename = s3Url.substring(s3Url.lastIndexOf("/") + 1);
        downloadLink.download = filename;

        // Append the anchor element to the body and click it programmatically
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up: remove the temporary anchor element
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }

    /* downloadFromS3 other method

    const downloadFromS3 = (fileUrl) => {
      fetch(fileUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error downloading file: " + response.statusText);
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "filename.mp3");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    };

    */

    return (
      <>
        <header>
          <HeaderContainer>
            {showSettings ? settings : null}
            {showExportOptions ? exportOptions : null}
            {showShortcuts ? shortCutKeys : null}
          </HeaderContainer>
        </header>
        <nav>{mediaUrl ? mediaControls : null}</nav>
        <HeaderContainers>
          <SettingsButton>
            <Keyboard
              style={{
                borderRadius: "5px",
                cursor: "pointer",
                fill: COLORS.BTN_GREEN,
              }}
              title="ShortCut Keys"
              onClick={() => setShowShortCuts(true)}
            />
          </SettingsButton>
          <SettingsButton>
            <CloudDownloadRounded
              style={{
                borderRadius: "5px",
                cursor: "pointer",
                fill: COLORS.BTN_GREEN,
              }}
              title="Export"
              onClick={() => setShowExportOptions(true)}
            />
          </SettingsButton>
          <SettingsButton>
            <div onClick={() => downloadFromS3(mediaUrl)}>
              <img
                src={require("../../../assets/images/JustAct Icons Download-02.svg")}
                alt="submit"
              />
            </div>
          </SettingsButton>
          {/* <SettingsButton>
                    <button
                        style={{ borderRadius: "5px" }}
                        title="settings"
                        onClick={() => setShowSettings(true)}
                    >
                        <Settings style={{ cursor: "pointer" }} />
                    </button>
                </SettingsButton>
                <SettingsButton>
                    <button
                        style={{ borderRadius: "5px" }}
                        title="Export"
                        onClick={() => setShowExportOptions(true)}
                    >
                        <CloudUploadRounded style={{ cursor: "pointer" }} />
                    </button>
                </SettingsButton> */}
        </HeaderContainers>
      </>
    );
  }
);

export default Header;

export const HeaderContainer = styled.div`
  margin-top: 0;
  margin-bottom: 1em;
  border-radius: 5px;
`;

export const HeaderContainers = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  display: flex;
`;

export const SettingsButton = styled.div`
  line-height: 1em;
  margin-left: 0.5em;
  background: ${COLORS.BORDER_GREY};
  display: inline-block;
  border: 0;
  border-radius: 5px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  width: 2em;
  height: 2em;

  &:hover {
    background: ${COLORS.BORDER_GREY};
  }
`;
